.sub-nav-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-nav-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.cat-name {
  font-size: 14px;
  color: var(--primary);
  text-align: center;
  margin-top: 5px;
}
.main-cat{
  text-transform: uppercase;
}

.user-drop-head{
  padding: 10px 15px;
  border-bottom: 1px solid var(--primary);
}
.suggestions{
  position: absolute;
  top: 41px;
  background-color: white;
  z-index: 11;
  width: 100%;
  border-radius: 20px;
}