.search {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .search .search-input {
    padding: 20px 15px;
    border-radius: 20px;
    border: none;
  }
  .search .search-btn {
    position: absolute;
    right: 0;
  }
  .search .search-btn span {
    border: none;
  }

  .custom-nav-link.active {
    color:var(--primary);
    background-color: var(--light) !important;
  }

  /* .sub-nav-link{
color:var(--primary) !important;  
  }

  .sub-nav-link:hover{
color:var(--secondary) !important;  
  } */

/* .......Mega Menu........ */

