:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --default: #2bbbad ;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #284b63;
  --light: #e8e8e8;
  --ofwhite: #F5F5F5;
  /* --secondary: #cedeff; */
  --secondary: #9EB7B8;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --dark: #415a77;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6C757D !important;
  text-align: left;
  background-color: #F5F5F5 !important;
  background-image: none !important;
}

ul{
  list-style: none;
}

.icons-mid {
  margin-right: .5rem;
  font-size: 20px;
}
.icons-light {
  color: var(--light);
}
.icons-dark {
  color: var(--primary) ;
}
