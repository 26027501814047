body {
  background-image: linear-gradient(45deg, black, var(--dark));
}

.registration-form {
  padding: 50px 0;
}

.registration-form form {
  background-color: #fff;
  max-width: 600px;
  margin: auto;
  padding: 50px 70px;
  border-radius: 30px;
  box-shadow: -2px 2px 8px 2px #20202057;
}

.registration-form .form-icon {
  text-align: center;
  background-color: var(--primary);
  border-radius: 50%;
  font-size: 40px;
  color: white;
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 50px;
  line-height: 100px;
}

.registration-form .item {
  border-radius: 20px;
  margin-bottom: 25px;
  padding: 10px 20px;
}

.registration-form .create-account {
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  background-color: var(--primary);
  border: none;
  color: white;
  margin-top: 20px;
}

@media (max-width: 576px) {
  .registration-form form {
    padding: 50px 20px;
  }

  .registration-form .form-icon {
    width: 70px;
    height: 70px;
    font-size: 30px;
    line-height: 70px;
  }
}


.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}
.form-check-input:focus{
  box-shadow: none;
}


.badg_warning,
.badg_danger,
.badg_default {
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 14px;
}
.badg_warning {
  background-color: #fff7e6;
  color: var(--warning);
}
.badg_danger {
  background-color: #fdf4f6;
  color: var(--danger);
}
.badg_default {
  background-color: #ebf9f4;
  color: var(--default);
}

.gradient-custom {
  /* fallback for old browsers */
  background: #cd9cf2;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to top left,
    rgba(205, 156, 242, 1),
    rgba(246, 243, 255, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to top left,
    rgba(205, 156, 242, 1),
    rgba(246, 243, 255, 1)
  );
}

/* ------Brofile Page------ */
ody {
  margin-top: 20px;
  background-color: #f2f6fc;
  color: #69707a;
}
.img-account-profile {
  height: 10rem;
}
.rounded-circle {
  border-radius: 50% !important;
}
.card {
  box-shadow: -1px 0rem 0.75rem 0 rgb(33 40 50 / 15%)
}

.card .card-header {
  font-weight: 500;
}
.card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
  padding: 1rem 1.35rem;
  margin-bottom: 0;
  background-color: rgba(33, 40, 50, 0.03);
  border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}
.form-control,
.dataTable-input {
  display: block;
  width: 100%;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5ccd6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
  color: #0061f2;
  border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}
.delete-fav{
  position: absolute;
    top: 5px;
    right: 1rem;
    font-size: 18px;
}
.delete-fav:hover{
    color:var(--primary);
    font-size: 19px;
    transition:0.1s
}

.img-active{
  border: 3px solid var(--primary);
  opacity: 0.8;
}

