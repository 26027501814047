
@media (max-width:600px){
  .carousel-item img{
    object-fit: cover;
    height: 40vh;
    max-width: 100vw;
  }
}
.slick-arrow  {
  z-index: 1;
}
.slick-arrow::before  {
  font-size: 30px;
}

.slick-prev{
    left: 0;
}

.slick-next{
    right: 9px;
}


