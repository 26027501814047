.primary-btn{
    color:var(--primary);
    background-color:var(--light);
    border: 1px solid var(--light);
    border-radius:5%;
}
.primary-btn:hover{
    color:var(--light);
    background-color:var(--primary);
}
.outline-btn{
    color:var(--light);
    border: 1px solid var(--light);
    border-radius:5%;
}
.outline-btn:hover{
    background-color:var(--primary);
}
.icon-btn{
    color:var(--primary);
    border: 1px solid var(--primary);
    border-radius:10%;
}
.icon-btn:hover{
    color:var(--light);
    background-color:var(--primary);
}
.op-btn{
    padding: 0px 5px;
    color:var(--primary);
}

.op-btn:hover,.op-btn:active{
    color:var(--light) !important;
    background-color:var(--primary) !important;
    border: 1px solid var(--primary) !important;
    border-radius:10% !important;
}

.cancel-btn{
    padding: 0px 5px;
    color: var(--primary);
    background-color: transparent;
    border: none;
}
.cancel-btn:hover,.cancel-btn:active{
    color:var(--light) !important;
    background-color:var(--primary) !important;
    border-radius:10% !important;
}

.disabel{
    opacity: 0.7;
    cursor: not-allowed !important;
}