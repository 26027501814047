.profile-menu {
  min-width: 8rem !important;
  right: 0 !important;
  left: unset;
}


.product-item{
  margin-right: 20px !important;
}

.next-arrow,
.prev-arrow{
  position: absolute;
  top: 48%;
  font-size: 30px;
  padding: 0;
  z-index: 2;
  color: var(--primary);
}
.prev-arrow{
  left: 0px;
}
.next-arrow{
  right: 15px;
}

.subcat{border: navajowhite;
  font-size: 14px;}

  .star{
    color: var(--warning) !important;
  }
