.cancel-modal {
  position: absolute;
  right: 5px;
  top: 5px;
}
.cancel-modal:hover {
  color: var(--primary);
  font-size: 19px;
  transition: 0.1s;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px); /* Apply the blur effect */
  z-index: 1040; /* Make sure the backdrop is behind the modal content */
}

.img-cont img {
  border: 1px solid var(--secondary);
  border-radius: 50%;
  width: 20px;
}

.selected-item {
  border-width: medium !important;
}

/* .range-form {
  width: 400px;
  margin: auto;
  padding: 50px;
}

.range-input{position: relative;}
.range-input input{    position: absolute;}
.range-input input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #1b53c0;
  background-color: #fff;
  pointer-events: auto;
  -webkit-appearance: none;
}
.range-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: #4caf50;
    cursor: pointer;
    border-radius: 50%;
  }
  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #4caf50;
    cursor: pointer;
  }
} */

.range-slider {
  height: 5px;
  position: relative;
  background-color: #e1e9f6;
  border-radius: 2px;
}
.range-selected {
  height: 100%;
  left: 30%;
  right: 30%;
  position: absolute;
  border-radius: 5px;
  /* background-color: #1b53c0; */
}
.range-input {
  position: relative;
}
.range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -7px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.range-input input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  /* border: 3px solid #1b53c0; */
  background-color: var(--primary);
  pointer-events: auto;
  -webkit-appearance: none;
}
.range-input input::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid #1b53c0;
  background-color: #fff;
  pointer-events: auto;
  -moz-appearance: none;
}

.range-values{
  display: flex;
  flex-direction: column;
  margin-top:1rem
}


.range-form {
  width: 400px;
  margin: auto;
  padding: 50px;
}

.range-slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 10px;
  border-radius: 5px;
  /* Specified height */
  background: #d3d3d3;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s;
  /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  &:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 18px;
    /* Set a specific slider handle width */
    height: 18px;
    /* Slider handle height */
    background: #4CAF50 !important;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 50%;
  }
  &::-moz-range-thumb {
    width: 18px;
    /* Set a specific slider handle width */
    height: 18px;
    /* Slider handle height */
    background: #4CAF50 !important;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
  }
}