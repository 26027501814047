.my-form {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form form {
  max-width: 100% !important;
}
.options {
  display: flex;
  justify-content: space-around;
}
.options .company,
.options .user {
  padding: 2rem;
  background-color: var(--light);
  border-radius: 50%;
}
.options .company:hover,
.options .user:hover {
  background-color: var(--secondary);
  transition: 0.3s;
}

.register-icon {
  font-size: 4rem;
  color: var(--primary);
}

.upload {
  position: relative;
  overflow: hidden;
}
.upload input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.react-date-picker {
  min-width: 100% !important;
  border: none !important;
}
.react-date-picker__wrapper {
  border-radius: 20px;
  color: gray;
  padding: 4px 18px;
  border: 1px solid var(--light) !important;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.step-item {
  flex: 1;
  text-align: center;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 0;
  background-color: #f9f9f9;
}

.step-item.active {
  background-color: #007bff;
  color: #fff;
}
.step-form {
  padding: 0 1rem;
}
.step-form select {
  padding: 0;
}
